<template>
  <van-pull-refresh style="height: 100%;overflow: scroll;" v-model="refreshing" @refresh="onRefresh">
    <van-list
        v-model="isLoading"
        :finished="finished"
        :loading-text="$t('loading')"
        :finished-text="$t('no-more')"
        @load="onLoad"
    >
      <ItemUserOrder v-for="item in list" :key="item.Id" :item="item" :source="source" />
    </van-list>
  </van-pull-refresh>
</template>
<script>
import ItemUserOrder from "./ItemUserOrder.vue";
import {MembersOrderExt, MerchantOrderExt} from "../../api/httpApi";
import {mapGetters} from "vuex";
import {getAccounts} from "../../utils/wallet";
export default {
  name: "UserOrderList",
  computed: {
    ...mapGetters(["protocol"])
  },
  props: {
    adId: [String,Number],
    source: String,
  },
  data() {
    return {
      isLoading: false,
      finished: false,
      refreshing: false,
      list: [],
      params: {
        Data: {
          AdId: 0,
          Address: "",
          Protocol: 0,
          NextAddress: "",
          OrderStatus: [0,1,2,3,4],
          OrderBy: 0
        },
        Page: 1,
        Size: 20
      }
    };
  },
  methods: {
    async onLoad() {
      const accounts = await getAccounts()
      this.params.Data.AdId = this.adId
      this.params.Data.Address = accounts[0]
      this.params.Data.Protocol = this.protocol
      const func = this.source === "user" ? MembersOrderExt : MerchantOrderExt
      const res = await func(this.params)
      const list = res.data
      if (list.length < this.params.Size) {
        this.finished = true
      }
      if (this.params.Page === 1) {
        this.list = list
      } else {
        this.list = this.list.concat(list)
      }
      this.refreshing = false;
      this.params.Page++
    },
    async onRefresh() {
      this.finished = false;
      this.loading = true;
      this.params.Page = 1;
      this.onLoad().then();
    },
    // status：（0：全部，1：待我操作，2：已完成，3：其他）
    async filterData(adId, status, sort) {
      this.params.Data.NextAddress = ""
      if (status === 0) {
        this.params.Data.OrderStatus = [0, 1, 2, 3, 4]
      } else if (status === 1) {
        const accounts = await getAccounts()
        this.params.Data.NextAddress = accounts[0]
        this.params.Data.OrderStatus = [1, 2]
      } else if (status === 2) {
        this.params.Data.OrderStatus = [1, 2]
      } else if (status === 3) {
        this.params.Data.OrderStatus = [3]
      } else {
        this.params.Data.OrderStatus = [4]
      }
      this.params.Data.OrderBy = sort
      if (adId > 0) {
        this.adId = adId
      }
      this.onRefresh().then()
    }
  },
  components: { ItemUserOrder },
};
</script>
<style></style>
