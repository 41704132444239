<template>
  <div class="box">
    <div class="header">
      <div class="header-left">
        <div class="header-left-text">{{ $t("order-num-title") }}：</div>
        <div class="header-left-value">{{item.OrderId}}</div>
      </div>
      <div class="header-right" :class="statusClass">
        {{ statusText }}
      </div>
    </div>
    <div class="line"></div>

    <div class="body">

      <div class="body-item">
        <div class="body-item-ii">
          <div class="body-item-text">{{ $t("order-price") }}</div>
          <div class="body-item-value">{{item.Price}} {{item.CurrencyName}}</div>
        </div>
        <div class="body-item-ii">
          <div class="body-item-text">{{ $t("order-type") }}</div>
          <div class="body-item-value">{{item.Mold === 0 ? '用户卖单' : '用户买单'}}</div>
        </div>
      </div>
      <div class="body-item-ii">
        <div class="body-item-text">{{ $t("order-num") }}</div>
        <div class="body-item-value">{{item.Num}} {{item.CoinName}}</div>
      </div>

      <div class="body-item">
        <div class="body-item-ii">
          <div class="body-item-text">{{ $t("order-amount") }}</div>
          <div class="body-item-value body-item-value-money">{{money}} {{item.CurrencyName}}</div>
        </div>

        <van-button
            class="body-item-btn"
            success
            @click="toDetail"
            color="#3665B7"
        >{{ $t("to-advertise-detail") }}</van-button>
      </div>

    </div>
  </div>
</template>
<script>
import BigNumber from "bignumber.js";

export default {
  name: "ItemUserOrder",
  props: {
    source: String,
    item: {
      type: Object,
      default() {
        return {
          Num: 0,
          Price: 0,
        }
      }
    },
  },
  computed: {
    status() {
      let status = this.item.OrderStatus
      const time = new Date().getTime() / 1000
      let diffTime = this.endTime - time
      if ((status === 1 || status === 2) && diffTime <= 0) {
        status = -1
      }
      return status
    },
    statusText() {
      const orderType = this.item.Mold
      const status = this.status
      // 买单
      let arr = {
        "-1": "订单超时，待用户取消",
        "0": "用户已取消",
        "1": "待用户付款",
        "2": "待商户收款",
        "3": "订单已完成",
        "4": "订单已冻结",
      }
      if (orderType === 0) {
        // 卖单
        arr = {
          "-1": "订单超时，待商户取消",
          "0": "商户已取消",
          "1": "待商户付款",
          "2": "待用户确定收款",
          "3": "订单已完成",
          "4": "订单已冻结",
        }
      }

      return arr[status]
    },
    statusClass() {
      const orderType = this.item.Mold
      const status = this.status
      // 买单
      let arr = {
        "-1": "status-err", // 订单超时，待用户取消
        "0": "status-err", // 用户已取消
        "1": "status-err", // 待用户付款
        "2": "status-green", // 待商户收款
        "3": "status-green", // 订单已完成
        "4": "status-err", // 订单已冻结
      }
      if (orderType === 0) {
        // 卖单
        arr = {
          "-1": "status-err", // 订单超时，待商户取消
          "0": "status-err", // 商户已取消
          "1": "status-err", // 待商户付款
          "2": "status-green", // 待用户确定收款
          "3": "status-green", // 订单已完成
          "4": "status-err", // 订单已冻结
        }
      }
      return arr[status]
    },
    money() {
      return new BigNumber(this.item.Num).times(new BigNumber(this.item.Price)).decimalPlaces(2).toString(10)
    }
  },
  data() {
    return {};
  },
  methods: {
    toDetail() {
      this.$router.push(`/order-detail?orderId=${this.item.OrderId}&source=${this.source}`);
    },
  },
};
</script>
<style scoped>
.box {
  padding: 32px 40px 40px;
  background-color: #FFFFFF;
  margin-bottom: 10px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-left {
  display: flex;
  align-items: center;
}

.header-right {
  color: #999999;
}

.header-left-text {
  color: #999999;
}

.header-left-value {
  font-size: 26px;
  color: #333333;
  font-weight: bold;
}

.line {
  background: linear-gradient(
      to left,
      transparent 0%,
      transparent 50%,
      #ccc 50%,
      #ccc 100%
  );
  height: 2px;
  margin-top: 20px;
  background-size: 10px 1px;
  background-repeat: repeat-x;
}

.body {
  margin-top: 12px;
}

.body-item {
  display: flex;
  justify-content: space-between;
}

.body-item-ii {
  display: flex;
  align-items: center;
  margin-top: 12px;
}

.body-item-text {
  color: #B3B3B3;
  font-size: 24px;
  margin-right: 20px;
  /*width: 96px;*/
  text-align: justify;
  text-align-last: justify;
}

.body-item-value {
  font-size: 24px;
  color: #333333;
}

.body-item-value-money {
  color: #0875c4;
}

.body-item-btn {
  font-size: 24px;
  height: 60px;
  border-radius: 8px
}

.status-err {
  color: #F96464;
}

.status-green {
  color: #66C3B7;
}


</style>
