<template>
  <div class="box">
    <div
      class="container-flex-h header tab-box"
    >
      <div class="container-flex-h-order tab-bg">
        <div
          class="tab-item"
          v-for="item in tabArr"
          :key="item.value"
          @click="tabChange(item)"
        >
          <p
            :style="{
              color:
                tabValue === item.value
                  ? '#0875C4'
                  : 'rgba(61, 61, 61, 0.5300)',
            }"
          >
            {{ item.text }}
          </p>
          <p :class="tabValue === item.value ? 'tab-item-select ' : ''"></p>
        </div>
      </div>
      <div class="container-flex-h-order">
        <div class="sort">
          <van-dropdown-menu>
            <van-dropdown-item @change="sortChange" v-model="sortValue" :options="sortArr" />
          </van-dropdown-menu>
        </div>
      </div>
    </div>

    <div class="user-order-center" v-if="adId > 0">
      <div class="order-list-ad">
        <span>广告编号 {{adId}}</span>
        <van-icon @click="closeAd" class="order-list-ad-close" name="close" size="14" color="#dcdee0" />
      </div>
    </div>

    <!-- content -->

    <UserOrderList class="list-box" style="flex: 1" :adId="adId" :source="source" ref="loadData" />
  </div>
</template>
<script>
import UserOrderList from "./UserOrderList.vue";
export default {
  name: "UserOrder",
  computed: {
    tabArr() {
      const tab2Text = this.source === "user" ? "待商户操作" : "待用户操作"
      return [
        { text: '全部', value: 0 },
        { text: '待我操作', value: 1 },
        { text: tab2Text, value: 2 },
        { text: '已完成', value: 3 },
        { text: '其它', value: 4 },
      ]
    }
  },
  data() {
    return {
      adId: 0,
      source: "user",
      tabValue: 0,
      sortValue: 0,
      sortArr: [
        { text: '默认', value: 0 },
        { text: '时间降序', value: 1 },
        { text: '时间升序', value: 2 },
      ],
    };
  },
  methods: {
    tabChange(item) {
      this.tabValue = item.value
      this.$refs.loadData.filterData(this.adId, this.tabValue, this.sortValue)
    },
    sortChange(value) {
      this.sortValue = value
      this.$refs.loadData.filterData(this.adId, this.tabValue, this.sortValue)
    },
    loadData() {
      this.$refs.loadData.filterData(this.adId, this.tabValue, this.sortValue)
    },
    closeAd() {
      this.adId = 0
      this.loadData()
    }
  },
  components: { UserOrderList },
  created() {
    this.adId = this.$route.query.adId
    this.source = this.$route.query.source || "user"
  },
};
</script>
<style scoped>
.header {
  padding: 0 16px;
}
.box {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.tab-box {
  /*margin-top: 24px;*/
  /*border-top: 2px solid #dcdee0;*/
  border-bottom: 2px solid #dcdee0;
  padding: 0 24px;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

.tab-bg {
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.tab-bg::-webkit-scrollbar {
  display: none;
}

.tab-item {
  margin: auto 16px;
}

.tab-item-select {
  color: #0875c4;
  height: 3px;
  opacity: 1;
  background-color: #0875c4;
}

.user-order-center {
  display: flex;
  padding: 0 32px;
}

.order-list-ad {
  position: relative;
  border-radius: 8px;
  color: #3D3D3D;
  border: 2px solid #dcdee0;
  padding: 2px 12px;
  margin: 24px 0 0;
}

.order-list-ad-close {
  position: absolute;
  top: -10px;
  right: -15px;
  border-radius: 18px;
  background-color: #FFFFFF;
}

.list-box {
  background-color: #F2F2F2;
}

</style>
